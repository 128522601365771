body,
html {
  font-weight: 200;
  font-family: "Poppins", sans-serif;
  margin: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  color: #000;
  background-color: #F8F2ED;
}

.wrapper {
  max-width: 1680px !important;
  margin: auto !important;
  padding-left: 200px !important;
  padding-right: 200px !important;
  @media (max-width: 1536px) {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  @media (max-width: 1280px) {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  @media (max-width: 768px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  @media (max-width: 640px) {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}
.news-container {
  max-width: 1100px;
  padding-left: 5.5rem;
  padding-right: 5.5rem;
  margin: 0 auto;
  @media (max-width: 1024px) {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
.wrapper-sm-md {
  margin: auto !important;
  @media (max-width: 768px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  @media (max-width: 640px) {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}

nav {
  z-index: 150;
}

.mobile-menu {
  z-index: 100;
}

.mobile-menu {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

#hamburger {
  background-color: #78B75E;
  padding: 5px;
  border-radius: 10%;
  -webkit-border-radius: 10%;
  -moz-border-radius: 10%;
  -ms-border-radius: 10%;
  -o-border-radius: 10%;
}

.mobile-menu.open {
  opacity: 1;
  transform: translateY(0);
}

.navbar-background {
  padding: 47px 0;
  transition: padding 0.3s ease, background-color 0.3s ease;
}

h1,
.h1 {
  letter-spacing: -2.6px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: responsive 40px 80px;
  line-height: responsive 37px 80px;
  margin-bottom: 25px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
    letter-spacing: -1.2px;
  }
}

h2,
.h2 {
  letter-spacing: -2.6px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: responsive 25px 40px;
  line-height: responsive 30px 45px;
  margin-bottom: 25px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
    letter-spacing: -1.2px;
  }
}

h3, .h3 {
  letter-spacing: -2.6px;
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-size: responsive 60px 120px;
  line-height: 45px;
  margin-bottom: 25px;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
  
  @media (max-width: 768px) { /* Tablet and below */
    font-size: 60px; /* Example adjusted value */
    line-height: 28px; /* Adjust if necessary */
    margin-bottom: 10px;
    letter-spacing: -1.2px;
  }

  @media (max-width: 480px) { /* Mobile */
    font-size: 60px; /* Example adjusted value */
    line-height: 24px; /* Adjust if necessary */
  }
}

.news-page{
  h3, h2,
.h2 {
  letter-spacing: -2.6px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: responsive 25px 40px;
  line-height: responsive 30px 45px;
  margin-bottom: 25px;
  color: black;
  @media (max-width: 768px) {
    margin-bottom: 10px;
    letter-spacing: -1.2px;
  }
}
}
h4,
.h4 {
  letter-spacing: -0.7px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: responsive 17px 40px;
  line-height: responsive 19px 45px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
  strong {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
  }
}

h5,
.h5 {
  letter-spacing: -0.7px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: responsive 17px 25px;
  line-height: responsive 19px 65px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
  strong {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
  }
}

p,
.p {
  letter-spacing: -0.5px;
  font-family: "Poppins", sans-serif;
  font-weight: 150;
  font-size: responsive 14px 16px;
  line-height: responsive 18px 26px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
    letter-spacing: -0.2px;
  }
  strong {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
  }
}

p {
  strong {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
  }
}

input {
  outline: none !important;
}

.custom-list-white li:before {
  content: '•';
  position: absolute;
  left: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-size: 120px;
  line-height: 35px;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
}

.custom-list-bg-dark li:before {
  content: '•';
  position: absolute;
  left: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-size: 120px;
  line-height: 35px;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
}

textarea#message, input#from-name, input#from-email {
  max-height: 33px;
}

.outlined-text {
  -webkit-text-stroke: 1px white;
  color: transparent;
}

.custom-list, .custom-list-bg-dark {
  list-style-type: none;
  padding-left: 0;
}

.custom-list li, .custom-list-bg-dark li {
  position: relative;
  padding-left: 4em;
}

@media (max-width: 767px) {
  .custom-list li, .custom-list-bg-dark li {
    padding-left: 2em;
  }
}

.custom-list li:before {
  content: '•';
  position: absolute;
  left: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-size: 120px;
  line-height: 35px;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
}

@media (max-width: 768px) {
  .custom-list li:before {
      font-size: 60px; /* Adjust as needed */
      line-height: 20px; /* Adjust as needed */
  }
}

#bg-dark-text {
  border-color: white;
}

@keyframes fade-down {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
}

@keyframes fade-up {
  from {
    transform: none;
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.nav-fade-down {
  opacity: 0;
  animation: fade-down 0.5s cubic-bezier(0.55, 0, 0.1, 1) forwards;
}
.nav-fade-up {
  opacity: 0;
  animation: fade-up 1s cubic-bezier(0.55, 0, 0.1, 1) forwards;
}

.fade-down {
  opacity: 0;
  animation: fade-down 1.5s cubic-bezier(0.55, 0, 0.1, 1) forwards;
  animation-delay: 0.2s;
}

*:focus {
  outline: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  top: 0;
  opacity: 0;
  background-color: #000000b0;
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  right: 0;
  z-index: 1;
}
/* Show the dropdown content when clicking on the dropdown container */
.dropdown.active .dropdown-content {
  opacity: 1;
  transform: translateY(0);
  display: block;
}

.fade-div {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.fade-out {
  opacity: 0;
  pointer-events: none; /* Disable interactions on the faded-out div */
  display: none !important; /* Hide the faded-out div */
}

// Fade ins
/* Add your global styles here */

/* Keyframes animation for smoother crossfade */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.h-max {
  height: max-content !important;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1rem;
  margin-top: 1.25rem; /* 20px equivalent */
  margin-bottom: 0; /* Reset the default margin-bottom */

  /* Media query for medium-sized screens */
  @media (min-width: 768px) {
    gap: 2rem;
  }

  /* Media query for large screens */
  @media (min-width: 1024px) {
    gap: 3rem;
  }
}

.wrapper .relative {
  position: relative;
}
.wrapper .absolute {
  position: absolute;
}

.service-overview {
  p {
    font-size: 16px;
    margin-bottom: 1rem;
    font-weight: 200;
    strong {
      font-size: 30px;
      line-height: 40px;
      font-weight: 200;
      @media (max-width: 700px) {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}

.cropped-image {
  clip-path: polygon(20% 0, 100% 0, 100% 80%, 80% 100%, 0 100%, 0 20%);
}

@media (max-width: 1024px) {
  .cropped-image {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 0 100%, 0 0);
  }
}

.cropped-service-image {
  clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%, 0 20%);
}

.progress-bar {
  background-color: #78B75E;
}

.swiper-slide.flickity-cell.is-selected {
  height: 800px;
}

.hideable-div {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-delay: 1.7s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.hideable-div-2 {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-delay: 2.6s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.hideable-div-3 {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-delay: 3.2s;
  animation-fill-mode: forwards;
  opacity: 0;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.scale-div {
  width: 100%;
  position: fixed; /* Stick to the top */
  top: 0; /* Position at the top */
  z-index: 1000; /* Ensure it's on top of other content */
}

.image-container {
  position: relative;
  display: inline-block; /* Adjust display property as needed */
  overflow: hidden;
}

.image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.9), transparent);
}

.image-container::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 100%;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.9), transparent);
}

.image-container:before,
.image-container:after {
  content: "";
  position: absolute;
  background: linear-gradient(rgba(0, 0, 0, 0.9), transparent);
  width: 100%;
  height: 80px;
}

.image-container::before {
  top: 0;
  left: 0;
}
.image-container::after {
  bottom: 0;
  left: 0;
}

.image-container img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}

#distortion-video-container {
  opacity: 1;
  transition: opacity 2s ease-in-out; /* Opacity transition over 2 seconds */
}

.hidden {
  display: none;
}

.col-text {
  p {
    margin-bottom: 0px;
  }
}

.accordion .right .accord-content {
  display: none;
}

.accordion .right .accord-content.active {
  transition-duration: 200ms !important;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
  display: grid !important;
}

.accordion {
  display: flex;
  @media (max-width: 1024px) {
    display: grid;
  }
}

.accordion .left {
  margin-right: 0px;
  width: 100%;
}

.accordion .right {
  width: 100%;
}

.accordion .left .accord-heading {
  @media (max-width: 1024px) {
    display: grid;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  padding-top: 35px;
  padding-bottom: 35px;
  cursor: pointer;
  padding: 10px;
  color: #202125b7;
}

.accord-heading {
  background-color: #d2d5ce00;
}

.accordion .left .accord-heading:hover {
  transition: all ease-in-out 200ms !important;
}

.accordion .left .accord-heading.active {
  @media (max-width: 700px) {
    height: 30px;
  }
  @media (max-width: 1024px) {
    height: 40px;
  }
  height: 130px;
  transition: all ease-in-out 200ms !important;
  background-color: #00f6be;
}

.accordion .right .accord-content {
  display: none;
}

.accordion .left .accord-text {
  display: none;
  overflow: auto; /* or overflow: scroll; */
  -webkit-overflow-scrolling: touch; /* For smoother scrolling on iOS devices */
  scrollbar-width: none; /* Hide the scrollbar on modern browsers */
  -ms-overflow-style: none; /* Hide the scrollbar on Internet Explorer and Microsoft Edge */

  &::-webkit-scrollbar {
    width: 0; /* Hide the scrollbar on WebKit browsers */
  }
}

.accordion .right .accord-content.active {
  display: grid;
  transition-duration: 200ms !important;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.accordion .left .accord-text.active {
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 86px;
  display: grid;
  @media (max-width: 1024px) {
    display: none;
  }
  transition-duration: 200ms !important;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
  display: grid;
}

.h3-green {
  p {
    @media (max-width: 768px) {
      letter-spacing: -0.5px;
    }
    letter-spacing: -1px;
    font-weight: 700;
    font-size: responsive 18px 27px;
    line-height: responsive 20px 30px;
    strong {
      color: #00f6be;
      font-weight: 700;
    }
  }
}

.h3-text {
  p {
    letter-spacing: -1.4px;
    font-weight: 700;
    font-size: responsive 20px 27px;
    line-height: responsive 21px 30px;
  }
}

/* Optional CSS in case you need more control */
.project-item img {
  width: 100%;
  height: auto;
  object-fit: cover; 
}

.h3-green2 {
  p {
    @media (max-width: 768px) {
      letter-spacing: -0.5px;
    }
    letter-spacing: -2.6px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: responsive 37px 65px;
    line-height: responsive 35px 64px;
    strong {
      color: #00f6be;
      font-weight: 700;
    }
  }
}

.h3-white {
  p {
    @media (max-width: 768px) {
      letter-spacing: -0.5px;
    }
    letter-spacing: -2.6px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: responsive 37px 65px;
    line-height: responsive 35px 64px;
    strong {
      color: #fff;
      font-weight: 700;
    }
  }
}

.h-max {
  height: max-content;
}

h1.max-w-\[850px\].pt-40 {
  line-height: 80px;
}

strong {
  font-weight: bold;
}

.flex.gap-10.w-full.text-lime, .lime-text, a.hover\:underline.text-lime {
  color: #78B75E;
}
.heading-text{
  strong{
    text-decoration: underline;
    text-decoration-color: #78B75Eed;
    text-decoration-style: solid;
    text-decoration-thickness: 8px;
    -moz-text-decoration-color: #78B75Eed;
}
}

h2.text-lime.heading-text {
  letter-spacing: .1px;
}
.custom-list-two, .custom-list-light {
  list-style-type: none; 
  padding-left: 0;
}

.custom-list-two li, .custom-list-light li {
  position: relative; /* allows absolute positioning of children */
  padding-left: 2em;
}

.custom-list-two li::before {
  content: ''; /* required for the pseudo-element to be used */
  position: absolute; /* positions relative to the list item */
  left: 0; /* aligns to the left of the padding space */
  top: 50%; /* centers vertically */
  transform: translateY(-50%); /* fine-tunes vertical centering */
  width: 10px; /* or whatever your design requires */
  height: 10px; /* or whatever your design requires */
  background-color: black; /* or whatever your design requires */
  border-radius: 50%; /* makes the bullet point round */
}

.custom-list-light li::before {
  content: ''; /* required for the pseudo-element to be used */
  position: absolute; /* positions relative to the list item */
  left: 0; /* aligns to the left of the padding space */
  top: 50%; /* centers vertically */
  transform: translateY(-50%); /* fine-tunes vertical centering */
  width: 10px; /* or whatever your design requires */
  height: 10px; /* or whatever your design requires */
  background-color: white; /* or whatever your design requires */
  border-radius: 50%; /* makes the bullet point round */
}


bg-dark {
  background-color: #262626 !important;
}

// Testimonial

.testimonial .right .testimonial-content {
  display: none;
  padding-top: 1px;
  overflow: auto; /* or overflow: scroll; */
  -webkit-overflow-scrolling: touch; /* For smoother scrolling on iOS devices */
  scrollbar-width: none; /* Hide the scrollbar on modern browsers */
  -ms-overflow-style: none; /* Hide the scrollbar on Internet Explorer and Microsoft Edge */

  &::-webkit-scrollbar {
    width: 0; /* Hide the scrollbar on WebKit browsers */
  }
}

.testimonial .right .testimonial-content.active {
  transition-duration: 200ms !important;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
  display: grid !important;
}

.testimonial {
  display: flex;
  @media (max-width: 1024px) {
    display: grid;
  }
}

.testimonial .left {
  margin-right: 0px;
  width: 100%;
}

.testimonial .right {
  width: 100%;
}

.testimonial .left .testimonial-heading {
  @media (max-width: 1024px) {
    display: grid;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  padding-top: 35px;
  padding-bottom: 35px;
  cursor: pointer;
  padding: 10px;
  color: #202125b7;
}

.testimonial-heading {
  background-color: #d2d5ce00;
}

.testimonial .left .testimonial-heading:hover {
  transition: all ease-in-out 200ms !important;
}

.testimonial .left .testimonial-heading.active {
  @media (max-width: 1024px) {
    height: 60px;
  }
  height: 150px;
  transition: all ease-in-out 200ms !important;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.327) 0px 3px 8px;
}

.testimonial .right .testimonial-content {
  display: none;
  overflow: auto; /* or overflow: scroll; */
  -webkit-overflow-scrolling: touch; /* For smoother scrolling on iOS devices */
  scrollbar-width: none; /* Hide the scrollbar on modern browsers */
  -ms-overflow-style: none; /* Hide the scrollbar on Internet Explorer and Microsoft Edge */

  &::-webkit-scrollbar {
    width: 0; /* Hide the scrollbar on WebKit browsers */
  }
}

.testimonial .left .testimonial-text {
  display: none;
  overflow: auto; /* or overflow: scroll; */
  -webkit-overflow-scrolling: touch; /* For smoother scrolling on iOS devices */
  scrollbar-width: none; /* Hide the scrollbar on modern browsers */
  -ms-overflow-style: none; /* Hide the scrollbar on Internet Explorer and Microsoft Edge */

  &::-webkit-scrollbar {
    width: 0; /* Hide the scrollbar on WebKit browsers */
  }
}

.testimonial .right .testimonial-content.active {
  display: grid;
  transition-duration: 200ms !important;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
  display: grid;
}

.testimonial .left .testimonial-text.active {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 86px;
  display: grid;
  transition-duration: 200ms !important;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
  display: grid;
}

textarea,
input.plain-text, /* this is a class */
input[type="text"],
input[type="button"],
input[type="submit"],
input[type=”email”],
input[type=”tel”],
input[type=”number”],
.input-radio-checkbox {
  /* this is a class */
  -webkit-appearance: none !important;
  border-radius: 0 !important;
}

input {
  outline: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -o-appearance: none !important;
  appearance: none !important;
}

/* Show the element with id "pound" */
#pound {
  display: block;
}


polygon {
  stroke-width: 1px;
}

ul.absolute.left-0.hidden.group-hover\:block.z-10.py-10.mt-1.w-48.text-white.rounded-md {
  background-color: rgba(0, 0, 0, 0.8);
}

.project-item {
  transition: transform 0.3s ease-in-out; /* Adds a smooth transition */
  cursor: pointer; /* Changes the cursor on hover */
}

.project-item:hover {
  transform: scale(1.05); /* Scales up the project item */
}

.project-item img {
  transition: transform 0.3s ease-in-out; /* Adds a smooth transition to the images */
}

.project-item:hover img {
  transform: scale(1.1); /* Scales up the image inside the project item */
}

h5.text-lime.heading-text.max-w-\[860px\].py-0 {
  line-height: 1;
}