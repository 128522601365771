.headroom--unpinned,
.headroom--pinned,
.headroom {
  .nav-phone-1 {
    display: none;
    color: #fff;
  }
  .nav-phone-2 {
    display: block;
    color: #fff;
  }
  .logo {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
    transition-property: all !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
    transition-duration: 200ms !important;
    height: 26px;
    @media (max-width: 768px) {
      height: 30px;
    }
    @media (max-width: 640px) {
      height: 30px;
    }
  }
  .nav-links {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
    transition-property: all !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
    transition-duration: 200ms !important;
    padding-top: 0px;
  }
  .nav-text {
    display: none;
  }
  background: #1c1c1c9b;
  height: 94px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  transition-duration: 250ms;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
  transition-duration: 200ms !important;
  @media (max-width: 1024px) {
    height: 80px;
  }
  @media (max-width: 768px) {
    background: #2222228f;
    height: 60px;
  }
  @media (max-width: 640px) {
    margin-top: 0;
    height: 60px;
  }
}

.headroom--top {
  .nav-phone-1 {
    display: block;
    color: #1c1c1c;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .nav-phone-2 {
    display: hidden;
    color: #fff;
  }
  .logo {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
    transition-property: all !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
    transition-duration: 200ms !important;
    height: 26px;
    @media (max-width: 768px) {
      height: 30px;
    }
    @media (max-width: 640px) {
      height: 30px;
    }
  }
  background: rgba(255, 255, 255, 0);
  @media (max-width: 640px) {
    background: #22222246;
  }
  .nav-links {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
    transition-duration: 250ms;
    transition-property: all !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
    transition-duration: 200ms !important;
    padding-top: 40px;
  }
  .nav-text {
    display: flex;
  }
  height: 94px;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
  transition-duration: 200ms !important;
  display: grid;
  @media (max-width: 640px) {
    margin-top: 0;
    height: 60px;
  }
}
