/*! Flickity v2.3.0
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: auto!important;
}

.scroll-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}
.flickity-slider {
  height: 100vh;
  max-height: 890px;
  width: 100%;
}
/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: rgba(0, 0, 0, 0.051);
  @media (max-width: 1024px) {
    background: rgb(0, 0, 0);
    color: #fff;
  }
  border: none;
  color: #000;
  transition: all 200ms ease-in-out;
}

.flickity-button:hover {
  background: #000;
  color: #fff;
  transition: all 200ms ease-in-out;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: none !important;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  @media (max-width: 1024px) {
    height: 35px;
    width: 35px;
  }
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: -50px;
  @media (max-width: 1024px) {
    left: -15px;
  }
}
.flickity-prev-next-button.next {
  right: -50px;
  @media (max-width: 1024px) {
    right: -15px;
  }
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: -40px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: -40px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */

.flickity-page-dots {
  display: none;
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  cursor: pointer;
}

.slider-button {
  svg {
    color: #fff !important;
  }
}

.slider-button.active {
  svg {
    color: #3af56d !important;
  }
}
